@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;

}

.navlist {
  @apply border-b border-border-color cursor-pointer py-2 text-base font-semibold leading-[50px] hover:bg-[#7FB8EF] hover:text-white flex items-center gap-3
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 10000;
  align-items: center;
}

.modal {
  background: white;
  border-radius: 20px;
  border: 1px solid #D9D9D9;

}

.highlightOption {
  background: #F5F5F5 !important;
  color: black !important;
}

.Dropdown-control {
  border: 1ps solid #F9F9F9 !important;
  background-color: #F9F9F9 !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
}
.custom-dropdown .Dropdown-control{
  @apply   border-0 !bg-white !p-0
}

.custom-dropdown .Dropdown-arrow {
  border-color: black transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 9px;
  width: 0;
}
.lead-table tbody {
  display: block;
  max-height: 250px;
  overflow-y: auto;
}

.lead-table table {
  width: 100%;
}

.lead-table tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}


/* custom scrollbar start */
::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: black;
  border: 2px solid transparent !important;
}

::-webkit-scrollbar-track {
  border: 1px solid #0000004D;
  border-radius: 10px;
  background: transparent;
  padding: 2px;
  margin: 5px;
}

.modal::-webkit-scrollbar-track {
  margin: 20px !important;
}

/* custom scrollbar end */
@media only screen and (max-width: 600px) {
  .Dropdown-control {
    padding: 8px 25px 8px 10px !important;
  }
}

.min-w-dropdown .Dropdown-control {
  min-width: 132px !important;
}

@media only screen and (min-width: 1390px) {
  .custom-padding {
    @apply pr-0
  }
}

@media only screen and (min-width: 768px) {
  .custom-padding {
    @apply pr-20
  }
}

.custom-bg {
  background-image: url("/public/assets/image/bordershape/blueshape.png");
  background-size: 100% 100%;
}

.custom-placeholder::placeholder {
  color: black;
}

/* From Uiverse.io by mrpumps31232 */
.loading-wave {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: #3498db;
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}

/* Loader css END */

.css-1u9des2-indicatorSeparator {
  display: none !important;
}


@keyframes fadeInLeft {
  from {
      opacity: 0;
      transform: translateX(-50px); /* Start from 50 pixels left of the initial position */
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 2s; /* Adjust the duration as needed */
  animation-fill-mode: both; /* Keeps the element visible after animation ends */
}

@keyframes popUp {
  from {
      transform: translateY(100px) scale(0);
      opacity: 0;
  }
  to {
      transform: translateY(0) scale(1);
      opacity: 1;
  }
}

.popUp {
  animation-name: popUp;
  animation-duration: 0.5s; /* Adjust the duration as needed */
  animation-timing-function: ease-out; /* This makes the animation start quickly and slow down towards the end */
  animation-fill-mode: both; /* Keeps the element visible after animation ends */
}
